import { lastDayOfQuarter, parseISO, startOfQuarter, sub, subQuarters } from 'date-fns'
import { format, toZonedTime } from 'date-fns-tz'

const formatInTimeZone = (date, fmt, tz) => format(toZonedTime(date, tz), fmt, { timeZone: tz })

const reformat = (str: string, to: any, withTimezone = true) => {
  const parsedDate = parseISO(str)
  if (withTimezone) {
    return formatInTimeZone(parsedDate, to, 'UTC')
  }
  return format(parsedDate, to)
}

const parseISOString = (str: string) => {
  const b: number[] = str.split(/\D+/) as any as number[]
  return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]))
}

// Get the last quarter date range for the given date.
const getLastQuarter = (date: Date) => {
  const lastQuarterStartDate = startOfQuarter(subQuarters(date, 1))
  const lastQuarterEndDate = lastDayOfQuarter(subQuarters(date, 1))

  return { start: lastQuarterStartDate, end: lastQuarterEndDate }
}

// Get the last calendar month date range for the given date.
const getLastCalendarMonth = (date: Date) => {
  const lastQuarterStartDate = sub(new Date(), { months: 1 })

  return { start: lastQuarterStartDate, end: date }
}

// Get the last calendar month date range for the given date.
const getLastCalendarYear = (date: Date): { start: Date; end: Date } => {
  const lastQuarterStartDate = sub(new Date(), { years: 1 })

  return { start: lastQuarterStartDate, end: date }
}

// Get the YTD range for the given date.
const getYTD = (date): { start: Date; end: Date } => {
  const yearStartDate = new Date(new Date().getFullYear(), 0, 1)
  return { start: yearStartDate, end: date }
}

export {
  reformat,
  parseISOString,
  getLastQuarter,
  getLastCalendarMonth,
  getLastCalendarYear,
  getYTD,
}
